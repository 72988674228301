import { AfterViewInit, Component } from '@angular/core';
import { ColDef, IRowNode, CsvExportParams } from 'ag-grid-community';
import { ApiServiceService } from 'src/app/api-service.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReferralModalComponent } from '../referral-modal/referral-modal.component';
import { ButtonCellRendererComponent } from '../button-cell-renderer/button-cell-renderer.component';
import { RecentNotesModalComponent } from '../recent-notes-modal/recent-notes-modal.component';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ClientDetailsModalComponent } from '../client-details-modal/client-details-modal.component';
import { InveriteModalComponent } from '../inverite-modal/inverite-modal.component';

@Component({
  selector: 'app-view-rapid-clients',
  templateUrl: './view-rapid-clients.component.html',
  styleUrls: ['./view-rapid-clients.component.scss']
})
export class ViewRapidClientsComponent implements AfterViewInit {
  public columnDefs: any[] = [];
  public rowData: any;
  public gridApi: any;
  public gridColumnApi: any;
  getRowStyle: any
  defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    // floatingFilter: true,
  };

  userInfo: any;
  receivedSelectedFilters: any;

  selectedFilters: Array<any> = [];

  filter_table = new FormGroup({
    created_at: new FormControl(''),
    file_status: new FormControl(''),
    department_name: new FormControl(''),
    created_by: new FormControl('')
  });

  statuses = [
    { insertValue: 'Approved', displayValue: 'Approved' },
    { insertValue: 'Pending', displayValue: 'Pending' },
    { insertValue: 'Declined', displayValue: 'Declined' }
  ];

  isBankingPermittedCompany: boolean | undefined;

  public defaultCsvExportParams: CsvExportParams = {
    allColumns: true,
  };

  constructor(private apiService: ApiServiceService, private router: Router, public dialog: MatDialog, private datepipe: DatePipe) {
    this.columnDefs = [
      {
        headerName: 'Note', field: 'first_name', minWidth: 90, maxWidth: 90, pinned: 'left',
        cellRenderer: ButtonCellRendererComponent,
        cellRendererParams: {
          matIconLabel: 'note',
          clicked: (data: any) => {
            // console.log(data?.zoho_applicationID, "********")
            this.openNotesModal(data?.zoho_applicationID);
          },
        }
      },
      { headerName: 'Created at', field: 'created_at', filter: 'agNumberColumnFilter', minWidth: 140, maxWidth: 140 },
      { headerName: 'First Name', field: 'first_name', filter: 'agTextColumnFilter' },
      { headerName: 'Middle Name', field: 'middle_name', filter: 'agTextColumnFilter', hide: true },
      { headerName: 'Last Name', field: 'last_name', filter: 'agTextColumnFilter', minWidth: 260, maxWidth: 260 },
      { headerName: 'File Status', field: 'rapid_status', filter: 'agTextColumnFilter', minWidth: 130, maxWidth: 130 },
      { headerName: 'Internal Stage', field: 'zoho_stage', filter: 'agTextColumnFilter' },
      {
        headerName: 'Banking', field: 'banking', minWidth: 120, maxWidth: 120,
        cellRenderer: ButtonCellRendererComponent,
        cellRendererParams: {
          matIconLabel: 'account_balance',
          clicked: (data: any) => {
            this.openInveriteModal(data);
          },
        }
      },
      { headerName: 'Loan Amount', field: 'loan_amount', filter: 'agNumberColumnFilter', minWidth: 150, maxWidth: 150 },
      { headerName: 'DOB', field: 'dob', filter: 'agDateColumnFilter', hide: true },
      { headerName: 'SIN', field: 'sin', filter: 'agTextColumnFilter', hide: true },
      { headerName: 'Email', field: 'email_address', filter: 'agTextColumnFilter', hide: true },
      { headerName: 'Mobile#', field: 'telephone', filter: 'agTextColumnFilter', hide: true },
      { headerName: 'Street', field: 'street', filter: 'agTextColumnFilter', hide: true },
      { headerName: 'City', field: 'city', filter: 'agTextColumnFilter', hide: true },
      { headerName: 'Province', field: 'province', filter: 'agTextColumnFilter', hide: true },
      { headerName: 'Postal Code', field: 'postal_code', filter: 'agTextColumnFilter', hide: true },
      { headerName: 'Loyalty Program', field: 'loyalty_program', filter: 'agTextColumnFilter', minWidth: 170, maxWidth: 170 },
      { headerName: 'Homeowner', field: 'home_owner', filter: 'agTextColumnFilter', minWidth: 135, maxWidth: 135 },
      { headerName: 'Deparment', field: 'department_name', filter: 'agTextColumnFilter', minWidth: 230, maxWidth: 230 },
      { headerName: 'Agent (ext)', field: 'referral_agent_ext', filter: 'agTextColumnFilter', hide: true },
      { headerName: 'Comments', field: 'comments', filter: 'agTextColumnFilter', hide: true },
      { headerName: 'Created by', field: 'full_name', filter: 'agTextColumnFilter', minWidth: 180, maxWidth: 180 }
    ]
    // this.defaultCsvExportParams = { fileName: 'lmc_rapid_' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + '.csv' };
  }
  ngAfterViewInit(): void {
  }

  // managerView() {
  //   if (this.userInfo?.roleID === 10) {
  //     return this.gridColumnApi?.setColumnsVisible(['creator_company'], false);
  //   }
  // }

  // agentView() {
  //   if (this.userInfo?.roleID === 11) {
  //     return this.gridColumnApi?.setColumnsVisible(['full_name'], false);
  //   }
  // }

  async getUserInfo() {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem('SESSIONID')) {
        this.apiService
          .verifyRapidSession(localStorage.getItem('SESSIONID'))
          .subscribe((res: any) => {
            if (res.status) {
              if (res.data.length !== 0) {
                this.userInfo = res?.data[0];
                // console.log(res)
                // console.log(this.userInfo)
                resolve(true);

              } else {
                this.router.navigate(['login']);
              }
            } else {
              this.router.navigate(['login']);
            }
          })
      } else {
        this.router.navigate(['login']);
      }
    })
  }
  departmentList: any;
  async onGridReady_RapidData(params: any) {
    try {
      const result = await this.getUserInfo();
      this.filterDisplay(this.userInfo?.roleID);
      this.departmentList = await this.getDepartmentDropdown(this.userInfo?.roleID, this.userInfo?.company_id);
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.getBankingPerimission();
      this.apiService.getAllRapidClients(this.userInfo).subscribe((res: any) => {
        let data = res.data;
        this.gridApi.setRowData(data);
        // this.managerView();
        // this.agentView();
        this.rowData = data
      })
    } catch (err) {
      console.log(err)
    }
  }

  openNewApp() {
    return this.userInfo?.company_id !== 5 ? this.router.navigate(['application']) : this.openReferralModal();
  }

  openReferralModal() {
    const dialogRef = this.dialog.open(ReferralModalComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result !== 'undefined')
        this.router.navigate(['application'], { queryParams: result });
      // console.log(result)
    });
  }

  async openNotesModal(applicationID: string) {
    const dialogRef = this.dialog.open(RecentNotesModalComponent, {
      width: '500px',
      data: {
        applicationID: applicationID
      }
    });
  }

  resetFilters() {
    this.getBankingPerimission();
    this.apiService.getAllRapidClients(this.userInfo).subscribe((res: any) => {
      this.selectedFilters = [];
      this.filter_table.reset();
      const data = res.data;
      this.gridApi.setRowData(data);
    })
  }

  applyFilter(selectedValue: any, selectedLabel: any) {
    const selectedItemIndex = this.selectedFilters.findIndex((item) => item.selectedLabel === selectedLabel);
    if (selectedLabel === 'created_at') {
      selectedValue = this.datepipe.transform(selectedValue, 'yyyy-MM-dd');
    }

    if (selectedItemIndex > -1) {
      this.selectedFilters[selectedItemIndex].selectedValue = selectedValue;
    } else {
      this.selectedFilters?.push({
        selectedValue,
        selectedLabel: selectedLabel,
      });
    }
    // console.log(this.selectedFilters, "------------")
    this.gridApi?.onFilterChanged();
  }

  isExternalFilterPresent(): boolean {
    return this.selectedFilters.length !== 0;
  }

  doesExternalFilterPass(node: IRowNode<any>) {
    if (node.data) {
      let result = this.selectedFilters.filter((filter: any) => node.data[filter.selectedLabel] == filter.selectedValue);
      return result.length === this.selectedFilters.length;
    }
    return true;
  }
  dptDisplay: boolean = false;
  byDisplay: boolean = false;
  filterDisplay(roleID: number) {
    const roles = [0, 1];
    this.dptDisplay = roles.includes(roleID) ? true : false;
  }

  getDepartmentDropdown(roleID: number, companyID: number) {
    return new Promise((resolve, reject) => {
      this.apiService.getDepartmentDropdown(roleID, companyID).subscribe((res) => {
        resolve(res);
      })
    })
  }

  employeeNameList: any;
  getDepartmentEmployees(department_name: string) {
    this.byDisplay = true;
    this.apiService.getDepartmentEmployees(department_name).subscribe((res) => {
      this.employeeNameList = res;
    })
  }

  openMoreDetails(e: any) {
    const { data } = e;
    const dialogRef = this.dialog.open(ClientDetailsModalComponent, {
      autoFocus: true,
      data,
    });
  }

  onBtnExport() {
    this.gridApi.exportDataAsCsv();
  }

  // ***** Inverite
  openInveriteModal(gridData: any) {
    const dialogRef = this.dialog.open(InveriteModalComponent, {
      width: '800px',
      data: {
        isButton: false,
        firstName: gridData?.first_name,
        lastName: gridData?.last_name,
        email: gridData?.email_address,
        employeeId: this.userInfo?.employeeID,
        applicationId: gridData?.zoho_applicationID,
        phone: gridData?.telephone,
        confirmClose: false
      }
    });
  }

  getBankingPerimission() {
    this.apiService.getBtnPermission().subscribe((res: any) => {
      const companyPermittedIds = res?.[0]?.rapid_banking;
      this.isBankingPermittedCompany = companyPermittedIds?.includes(this.userInfo?.company_id) ? true : false;
      return this.isBankingPermittedCompany ? this.gridColumnApi?.setColumnsVisible(['banking'], true) : this.gridColumnApi?.setColumnsVisible(['banking'], false);
    })
  }

}