<div class="row">
    <div class="col">
        <mat-card style="height: 100%; border: 1px solid rgb(216, 217, 217);">
            <mat-card-title style="font-size: large;">Application Preview
                <hr>
            </mat-card-title>
            <mat-card-content style="display: flex; flex-direction: column;">
                <div *ngIf="triggerNotPrequalified">
                    <p>Do you earn at least $1200? : <strong>{{receivedRapidData?.prequalify?.income_1200 === true ?
                            'Yes' :
                            'No'}}</strong></p>
                    <p>Are you currently a Canadian Citizen or Permanent Resident? :
                        <strong>{{receivedRapidData?.prequalify?.residency === true ? 'Yes' :
                            'No'}}</strong>
                    </p>
                    <p>Are you currently employed (Completed probation) or self-employed? :
                        <strong>{{receivedRapidData?.prequalify?.employed === true ? 'Yes' :
                            'No'}}</strong>
                    </p>
                    <p>Not currently in Bankruptcy, Consumer Proposal or Credit Counselling? :
                        <strong>{{receivedRapidData?.prequalify?.bankruptcy === true ? 'Yes' :
                            'No'}}</strong>
                    </p>
                    <p>Do you reside outside of the province of Quebec? :
                        <strong>{{receivedRapidData?.prequalify?.reside_quebec === true ? 'Yes' :
                            'No'}}</strong>
                    </p>
                    <p>Do you live outside of native reserve? :
                        <strong>{{receivedRapidData?.prequalify?.native_reserve === true ? 'Yes' :
                            'No'}}</strong>
                    </p>
                    <p>No active payday loans? :
                        <strong>{{receivedRapidData?.prequalify?.payday_loans === true ? 'Yes' :
                            'No'}}</strong>
                    </p>
                    <p>Do you have less than three NSF's in the last 90days? :
                        <strong>{{receivedRapidData?.prequalify?.nsfs === true ? 'Yes' :
                            'No'}}</strong>
                    </p>
                </div>
                <div *ngIf="!triggerNotPrequalified">
                    <div class="row">
                        <div class="col-4">
                            <label>First Name :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.first_name}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Middle Name :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.middle_name}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Last Name :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.last_name}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Date of Birth :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.dob | date: 'MM/dd/yyyy'}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Email :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.email_address}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Mobile Phone :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.telephone}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Address :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.address}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>City : </label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.city}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Province :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.province}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Postal Code :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.postal_code}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Loyalty Program :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.loyalty_program}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Homeowner :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.home_owner}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Loan Amount :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.loan_amount}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>EDP Account No.:</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.edp_number}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Payment Method :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.payment_method}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Collector (extension) :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.referral_agent_ext}}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label>Comments :</label>
                        </div>
                        <div class="col-8"><label>{{receivedRapidData?.client?.comments}}</label></div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col" *ngIf="!showCard && !triggerNotPrequalified">
        <mat-card style="height: 100%; border: 1px solid rgb(216, 217, 217);">
            <mat-card-title style="font-size: large;">Select the Creditors
                <hr>
            </mat-card-title>
            <mat-card-content>
                <table>
                    <thead>
                        <th>Creditor</th>
                        <th>Balance</th>
                        <th>Referral</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let trade of tradesList; index as tradeIndex">
                            <td *ngIf="trade.Balance > 0 && trade.Type[0] !== 'M' && hideIndex">{{tradeIndex}}</td>
                            <td *ngIf="trade.Balance > 0 && trade.Type[0] !== 'M'"
                                [ngClass]="{'highlight-referral':TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[tradeIndex]['Referral'] == true}">
                                {{ trade.MemberName }}
                            </td>
                            <td *ngIf="trade.Balance > 0 && trade.Type[0] !== 'M'"
                                [ngClass]="{'highlight-referral':TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[tradeIndex]['Referral'] == true}">
                                {{ trade.Balance }}
                            </td>
                            <td *ngIf="trade.Balance > 0 && trade.Type[0] !== 'M'"
                                [ngClass]="{'highlight-referral':TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[tradeIndex]['Referral'] == true}">
                                <mat-checkbox
                                    [(ngModel)]="TU_Report.REL4Report.TU_FFR_Report[0].Trades[0].Trade[tradeIndex]['Referral']"></mat-checkbox>
                            </td>
                        </tr>
                        <tr *ngFor="let collection of collectionList; index as collectionIndex">
                            <td
                                *ngIf="collection.CurrentBalance > 0 && !collectionList.includes('FAMILY SUPPORT') && hideIndex">
                                {{collectionIndex}}</td>
                            <td *ngIf="collection.CurrentBalance > 0 && !collectionList.includes('FAMILY SUPPORT')"
                                [ngClass]="{'highlight-referral':TU_Report.REL4Report.TU_FFR_Report[0].Collections[0].Collection[collectionIndex]['Referral'] == true}">
                                {{ collection.MemberName }}/{{ collection.Creditor }}
                            </td>
                            <td *ngIf="collection.CurrentBalance > 0 && !collectionList.includes('FAMILY SUPPORT')"
                                [ngClass]="{'highlight-referral':TU_Report.REL4Report.TU_FFR_Report[0].Collections[0].Collection[collectionIndex]['Referral'] == true}">
                                {{collection.CurrentBalance}}</td>
                            <td *ngIf="collection.CurrentBalance > 0 && !collectionList.includes('FAMILY SUPPORT')"
                                [ngClass]="{'highlight-referral':TU_Report.REL4Report.TU_FFR_Report[0].Collections[0].Collection[collectionIndex]['Referral'] == true}">
                                <mat-checkbox
                                    [(ngModel)]="TU_Report.REL4Report.TU_FFR_Report[0].Collections[0].Collection[collectionIndex]['Referral']"></mat-checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td [ngClass]="{'highlight-referral':otherCreditor == true}">
                                <input style="width: 100%;" type="text" class="textStyle"
                                    placeholder="Please enter creditor" [formControl]="otherCreditor_name" />
                            </td>
                            <td [ngClass]="{'highlight-referral':otherCreditor == true}">
                                <input style="width: 100%;" type="number" class="textStyle"
                                    placeholder="Please enter creditor balance" [formControl]="otherCreditor_amount" />
                            </td>
                            <td [ngClass]="{'highlight-referral':otherCreditor == true}">
                                <mat-checkbox [(ngModel)]="otherCreditor"
                                    [disabled]="otherCreditor_name.value === '' || otherCreditor_amount.value === '' ? true : false"></mat-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style="margin-top: 10px; font-weight: bold;" *ngIf="!disablePreLoanAmount">Loan Amount:
                    <input style="width: 25%;" type="number" class="textStyle" [formControl]="loan_amount"
                        (click)="enableEditAmount()" (mousedown)="enableEditAmount()" [min]="loanConfig?.min_loan"
                        [max]="loanConfig?.max_loan" required />
                    <span *ngIf="displayEditIcons" class="displayEditIcons">
                        <mat-icon style=" color: #3f51b5;" (click)="updatePreLoanAmount()"
                            *ngIf="!loan_amount.invalid">check_circle</mat-icon>
                        <mat-icon (click)="hideEditIcons()">highlight_off</mat-icon>
                    </span>
                    <mat-error *ngIf="loan_amount.hasError(
                        'required'
                    ) && loan_amount.touched
                ">
                    </mat-error>
                    <mat-error *ngIf="loan_amount.value < loanConfig?.min_loan">
                        Loan amount must be greater than {{loanConfig?.min_loan}}
                    </mat-error>
                    <mat-error *ngIf="loan_amount.value > loanConfig?.max_loan">
                        Loan amount must be less than {{loanConfig?.max_loan}}
                    </mat-error>
                </p>
            </mat-card-content>
            <button style="width: 20%;" mat-raised-button color="primary" (click)="submitRapidApproval()"
                [disabled]="disableSubmit || btnSubmitSpinner">
                <span *ngIf="btnSubmitSpinner" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                <span *ngIf="btnSubmitShowText">Submit</span>
            </button>
        </mat-card>
    </div>
    <div class="col" *ngIf="triggerNotPrequalified">
        <mat-card style="height: 100%; border: 1px solid rgb(216, 217, 217);">
            <h3>Manual review
                <mat-icon style="color: #ffc800; vertical-align: text-bottom;">error</mat-icon>
            </h3>
            Contact LM Credit Officer
        </mat-card>
    </div>
    <div class="col" *ngIf="showCard">
        <mat-card style="height: 100%; border: 1px solid rgb(216, 217, 217);">
            <mat-card-title style="font-size: large;">Application Result
                <hr>
            </mat-card-title>
            <mat-card-content *ngIf="insertedIDs">
                <div *ngIf="knockoutStatus">
                    <h3>Rapid decline</h3>
                    <p>File has been <strong>declined</strong> by the Rapid Program for the following reasons:</p>
                    <ul>
                        <li>{{declineMessage}}</li>
                    </ul>
                    <hr>
                    <p>This file has been submitted to LM Credit as a normal application.</p>
                    <p>Please call toll free number +1(888) 201-1676 or email at rapid@lmcredit.com</p>
                </div>
                <div *ngIf="approval_status">
                    <h3>Approved
                        <mat-icon style="color: green; vertical-align: text-bottom;">check_circle</mat-icon>
                    </h3>
                    <div class="inside-card">
                        <p>File has been approved for ${{receivedRapidData.client.loan_amount}}.</p>
                        <p>Please call toll free number +1(888) 201-1676</p>
                        <p>Email at rapid@lmcredit.com</p>
                        <button style="width: 50%;" mat-raised-button color="primary"
                            (click)="createClientFileUploadLink()"
                            [disabled]="externalLinkBtn || btnSpinner || !emailRequired">
                            <span *ngIf="btnLinkSpinner" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                            <span *ngIf="btnLinkShowText">{{externalLinkBtnText}}</span>
                        </button>
                    </div>
                </div>
                <div *ngIf="!approval_status && !knockoutStatus" style="margin-top: 30px;">
                    <section class="fieldset" *ngIf="fallBackStatus && !approval_status else regularPending">
                        <h1>Option 1</h1>
                        <h3 class="title-spacing">Pending manual review for ${{receivedRapidData.client.loan_amount}}
                            <mat-icon style="color: #ffc800; vertical-align: text-bottom;">error</mat-icon>
                        </h3>
                        <!-- <div class="inside-card"> -->
                        <div>
                            <p>Please provide additional documents:</p>
                            <ol>
                                <li>ID</li>
                                <li *ngIf="bankingRequired">Banking statements</li>
                                <li>Pay stubs</li>
                            </ol>
                            <button style="margin-bottom: 10px;" mat-raised-button color="primary"
                                (click)="openInveriteModalButton()"
                                *ngIf="bankingRequired && isBankingPermittedAgency">Send
                                Bank Request</button>
                            <div *ngIf="tier2Status && !approval_status">
                                <p>Please call toll free number +1(888) 201-1676</p>
                                <p>Email at rapid@lmcredit.com</p>
                            </div>
                            <button style="width: 50%;" mat-raised-button color="primary"
                                (click)="createClientFileUploadLink()"
                                [disabled]="externalLinkBtn || btnSpinner || !emailRequired">
                                <span *ngIf="btnLinkSpinner" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                                <span *ngIf="btnLinkShowText">{{externalLinkBtnText}}</span>
                            </button>
                        </div>
                    </section>
                    <ng-template #regularPending>
                        <h3 class="title-spacing">Pending manual review for ${{receivedRapidData.client.loan_amount}}
                            <mat-icon style="color: #ffc800; vertical-align: text-bottom;">error</mat-icon>
                        </h3>
                        <div class="inside-card">
                            <p>Please provide additional documents:</p>
                            <ol>
                                <li>ID</li>
                                <li *ngIf="bankingRequired">Banking statements</li>
                                <li>Pay stubs</li>
                            </ol>
                            <button style="margin-bottom: 10px;" mat-raised-button color="primary"
                                (click)="openInveriteModalButton()"
                                *ngIf="bankingRequired && isBankingPermittedAgency">Send
                                Bank Request</button>
                            <div *ngIf="tier2Status && !approval_status">
                                <p>Please call toll free number +1(888) 201-1676</p>
                                <p>Email at rapid@lmcredit.com</p>
                            </div>
                            <button style="width: 50%;" mat-raised-button color="primary"
                                (click)="createClientFileUploadLink()"
                                [disabled]="externalLinkBtn || btnSpinner || !emailRequired">
                                <span *ngIf="btnLinkSpinner" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                                <span *ngIf="btnLinkShowText">{{externalLinkBtnText}}</span>
                            </button>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="fallBackStatus && !approval_status" style="margin-top: 30px;">
                    <section class="fieldset" style="padding-bottom: 0;">
                        <h1>Option 2</h1>
                        <h3 class="title-spacing">Preapproved for up to ${{fallBackAmount}}</h3>
                        <!-- <div class="inside-card"> -->
                        <div>
                            <p>The client has been approved for up to ${{fallBackAmount}}. To adjust the application
                                amount,
                                please use the <strong>revised amount</strong> below.</p>
                            <mat-form-field style="width: 40%; margin-top: 1%;">
                                <mat-label>Revised Amount</mat-label>
                                <input matInput type="number" [formControl]="revised_amount"
                                    [min]="loanConfig?.min_loan" [max]="fallBackAmount" required />
                                <mat-error *ngIf="revised_amount.hasError(
                                    'required'
                                ) && revised_amount.touched
                            ">
                                </mat-error>
                                <mat-error *ngIf="revised_amount.value < loanConfig?.min_loan">
                                    Loan amount must be greater than {{loanConfig?.min_loan}}
                                </mat-error>
                                <mat-error *ngIf="revised_amount.value > approved_amount">
                                    Loan amount must be less than {{approved_amount}}
                                </mat-error>
                            </mat-form-field>
                            <button style="margin-left: 1em; width: 20%;" mat-raised-button color="accent"
                                (click)="applyRevisedAmount()" [disabled]="revised_amount.invalid || btnSpinner">
                                <span *ngIf="btnSpinner" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                                <span *ngIf="btnShowText">Update</span>
                            </button>
                        </div>
                    </section>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>